<template>
  <v-dialog
    max-width="800px"
    width="100%"
    :value="true"
    scrollable
    @keydown.esc="close"
  >
    <v-card>
      <v-progress-linear
        indeterminate
        color="info"
        v-if="loading"
      ></v-progress-linear>
      <v-card-title class="pa-0">
        <v-toolbar color="info" dark class="mb-2">
          <v-btn icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
          <v-toolbar-title>Account </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text v-if="item"
        ><RowItem align="center" title="Person">
          <PersonItem :value="item.person" />
        </RowItem>
        <v-divider class="mb-4"></v-divider>
        <RowItem title="Kontoname kurz">
          <StaticText :value="item.samAccountName" />
        </RowItem>
        <RowItem title="Kontoname lang">
          <StaticText :value="item.userPrincipalName" />
        </RowItem>
        <RowItem title="Status">
          <LookupValue :value="item.status" />
          <span v-if="item.statusChangedOn"
            ><br /><DateValue :value="item.statusChangedOn"> </DateValue
          ></span>
          <v-icon v-if="item.statusOnHold" color="error" class="ml-4"
            >mdi-lock</v-icon
          >
        </RowItem>
        <RowItem title="ID Person KINET">
          <StaticText :value="item.person.id" />
        </RowItem>
        <RowItem title="ID Person Evento">
          <StaticText :value="item.person.eventoId" />
        </RowItem>
        <RowItem title="Ausweis / Druckkarte"
          ><tt><StaticText :value="item.legicUid" /></tt>
        </RowItem>
        <RowItem title="Anzahl gedruckte Ausweise">
          <StaticText :value="item.cardCount" />
        </RowItem>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="item"
          text
          color="error"
          @click="toggleStatusOnHold"
          :loading="saving"
          >Status {{ item.statusOnHold ? "entsperren" : "sperren" }}</v-btn
        >
        <v-spacer />
        <LookupValueInput
          v-if="item"
          single-line
          hide-details
          :value="item.status"
          :items="statuses"
          @input="toggleStatus"
          class="mb-2 mt-n2"
          :loading="saving"
        />
        <v-spacer />
        <v-btn text @click="close">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import LookupValue from "common/components/LookupValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "@/components/PersonItem";
import RowItem from "@/components/RowItem";
import StaticText from "common/components/StaticText.vue";

export default {
  name: "AccountDetails",
  components: {
    DateValue,
    LookupValue,
    LookupValueInput,
    PersonItem,
    RowItem,
    StaticText,
  },
  props: ["id"],
  data() {
    return {
      create: false,
      item: null,
      loading: false,
      saving: false,
      statuses: [],
    };
  },

  methods: {
    close() {
      this.$router.back();
    },
    async toggleStatus(status) {
      this.saving = true;
      await this.apiPatch({
        resource: "account/account",
        id: this.item.id,
        key: "status",
        value: status,
      });
      this.saving = false;
      this.fetchData();
      this.$emit("dataChanged");
    },
    async toggleStatusOnHold() {
      this.saving = true;
      await this.apiPatch({
        resource: "account/account",
        id: this.item.id,
        key: "statusOnHold",
        value: !this.item.statusOnHold,
      });
      this.saving = false;
      this.fetchData();
      this.$emit("dataChanged");
    },
    async fetchData() {
      {
        this.loading = true;
        if (this.statuses.length == 0) {
          this.statuses = await this.apiList({
            resource: "account/status",
          });
        }
        this.item = await this.apiGet({
          resource: "account/account",
          id: this.id,
        });
        this.loading = false;
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
